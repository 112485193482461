import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFetcher } from "react-router-dom";
import { setPosts } from "state";
import PostWidget from "./PostWidget";
import API from "../../api";

function PostsWidget({ userId, isProfile = false }) {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.posts);
  const token = useSelector((state) => state.token);

  useEffect(() => {
    const getPosts = async () => {
      const response = await API(`posts`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.data;
      dispatch(setPosts({ posts: data }));
    };
    if (!isProfile) {
      getPosts();
    }
  }, [isProfile]);

  useEffect(() => {
    const getUserPosts = async () => {
      const response = await API(`posts/${userId}/posts`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.data;
      dispatch(setPosts({ posts: data }));
    };

    if (isProfile) getUserPosts();
  }, [userId, isProfile]);

  const reversePosts = posts.concat().reverse();

  console.log(posts.concat());
  return (
    <>
      {reversePosts
        // .reverse()
        .map(
          ({
            _id,
            userId,
            firstName,
            lastName,
            description,
            location,
            picturePath,
            userPicturePath,
            likes,
            comments,
          }) => (
            <PostWidget
              key={_id}
              postId={_id}
              postUserId={userId}
              name={`${firstName} ${lastName}`}
              description={description}
              location={location}
              picturePath={picturePath}
              userPicturePath={userPicturePath}
              likes={likes}
              comments={comments}
            />
          )
        )}
    </>
  );
}

export default PostsWidget;
